import React, { useEffect } from 'react'

const pageTitle = 'Overmoon | About'
const pageDescription = `Overmoon is consolidating the best vacation homes to build a company that the whole world can trust with their vacations.`

export const Head = () => (
  <>
    <title>{pageTitle}</title>
    <meta title="title" property="og:title" content={pageTitle} />
    <meta
      name="description"
      property="og:description"
      content={pageDescription}
    />
  </>
)

const About = () => {
  useEffect(() => {
    window.location.href = 'https://overmoon.com/about'
  }, [])
  return null
}

export default About
